$(document).ready(function() {

  if ( _.get($.siteConfiguration, ["enable_esp", "enable_care_page"] ) ){
    renderMarkdown($('#care-page-headers'), $.polyglot.t('care_page_headers', $.interpolations));
    renderMarkdown($('#care-invite-page-headers'), $.polyglot.t('care_invite_page_headers', $.interpolations));
    renderMarkdown($('.care-explanation'), $.polyglot.t('care_explanation', $.interpolations));
    renderMarkdown($('#care-benefits-explanation'), $.polyglot.t('care_benefits_explanation', $.interpolations));
    renderMarkdown($('#care-disclaimer'), $.polyglot.t('care_disclaimer', $.interpolations));
    renderMarkdown($('#care-terms'), $.polyglot.t('care_terms', $.interpolations));
    renderMarkdown($('#care-faq'), $.polyglot.t('care_faq', $.interpolations));
    renderMarkdown($('#care-faq-condensed'), $.polyglot.t('care_faq_condensed', $.interpolations));
    renderMarkdown($('#care-benefit-disclaimer-text'), $.polyglot.t('care_benefit_disclaimer_text', $.interpolations));
  }
  if ( _.get($.siteConfiguration, "enable_esp_sales" ) ){
    renderMarkdown($('#protect-page-product-image-text'), $.polyglot.t('protect_page_product_image_text', $.interpolations));
    renderMarkdown($('#protect-page-product-image-text-2'), $.polyglot.t('protect_page_product_image_text_2', $.interpolations));
    renderMarkdown($('#protect-page-product-image-text-3'), $.polyglot.t('protect_page_product_image_text_3', $.interpolations));
    renderMarkdown($('#protect-page-product-intro'), $.polyglot.t('protect_page_product_intro', $.interpolations));
    renderMarkdown($('#protect-page-benefit-heading-text'), $.polyglot.t('protect_page_benefit_heading_text', $.interpolations));
    renderMarkdown($('#protect-page-activate-text'), $.polyglot.t('protect_page_activate_text', $.interpolations));
    renderMarkdown($('#protect-page-banner-text'), $.polyglot.t('protect_page_banner_text', $.interpolations));
  }
  if ( _.get($.siteConfiguration, "enable_esp_sales" ) ){
    renderMarkdown($('#care_plan_1_message'), $.polyglot.t('care_plan_1_message', $.interpolations));
    renderMarkdown($('#care_plan_2_message'), $.polyglot.t('care_plan_2_message', $.interpolations));
    renderMarkdown($('#renewal-decline-text'), $.polyglot.t('care_decline_message', $.interpolations));
    renderMarkdown($('#care-benefits-heading-text'), $.polyglot.t('esp_benefits_title_heading', $.interpolations));
  }
});

$(document).ready(function() {
  renderMarkdown($('#footer-text'), $.polyglot.t('footer_text', $.interpolations));
  if ( _.get($.siteConfiguration,"enable_footer_links" ) ){
    renderMarkdown($('#footer_links'), $.polyglot.t('footer_links', $.interpolations));
  }
});

$(document).ready(function() {
  if (!localStorage.bannerClosed) {
    $('.cookieinfo-banner').css('display', 'inherit');
  } else {
    $('.cookieinfo-banner').css('display', 'none');
  }
  $('.cookieinfo-accept').on('click', function() {
    $('.cookieinfo-banner').css('display', 'none');
    $('#cookie_policy_accepted').val(1);
    localStorage.bannerClosed = 'true'
  });
});